var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-1"
  }, [_vm.isShowConfirm ? _c('div', [_c('ConfirmDataBooking', {
    attrs: {
      "payload": _vm.payload
    },
    on: {
      "back": function back() {
        return _vm.isShowConfirm = false;
      },
      "saveBooking": _vm.saveBookingHandle
    }
  })], 1) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowConfirm,
      expression: "!isShowConfirm"
    }]
  }, [_c('NavBarSearch', {
    attrs: {
      "payload": _vm.payloadSearch
    }
  }), _c('ResultHeaderTab'), _c('ValidationObserver', {
    ref: "refCreateTicketPaxInput"
  }, [_c('b-tabs', {
    attrs: {
      "nav-wrapper-class": "d-none"
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, _vm._l(_vm.getSearchPayloadArray, function (_, itineraryIndex) {
    return _c('b-tab', {
      key: itineraryIndex
    }, [_vm.loading ? [_c('div', {
      staticClass: "d-flex-center text-primary mt-2 py-2"
    }, [_c('b-spinner', {
      staticClass: "mr-50",
      attrs: {
        "variant": "primary"
      }
    }), _c('span', [_vm._v(_vm._s(_vm.$t('train.search.result.searching')))])], 1)] : _vm.isEmpty(_vm.getResultTrainArray[itineraryIndex]) && !_vm.loading ? [_c('b-alert', {
      staticClass: "my-2 my-lg-3",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('div', {
      staticClass: "text-center alert-heading font-medium-4"
    }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.noTrainFound')) + " ")])])] : !_vm.isEmpty(_vm.getResultTrainArray[itineraryIndex]) ? [_c('div', [_c('TrainFront', {
      attrs: {
        "itineraryIndex": itineraryIndex,
        "trains": _vm.getResultTrainArray[itineraryIndex]
      }
    })], 1), _c('div', [_c('TrainCoachList', {
      attrs: {
        "itineraryIndex": itineraryIndex
      }
    })], 1), _c('div', [_c('CardPaxData', {
      attrs: {
        "itineraryIndex": itineraryIndex
      }
    })], 1)] : _vm._e()], 2);
  }), 1), _c('b-row', {
    staticClass: "align-items-center py-75 gap-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "py-25"
  }, [_c('b-button', {
    staticClass: "px-75 text-nowrap",
    attrs: {
      "variant": "outline-danger",
      "size": "sm"
    },
    on: {
      "click": _vm.deleteAllSelectTicket
    }
  }, [_vm._v(" " + _vm._s(_vm.isMobileView ? _vm.$t('train.search.result.clearAllTickets') : _vm.$t('train.search.result.clearSelectedTickets')) + " ")])], 1), _c('b-col', {
    staticClass: "d-flex order-2 order-sm-0 py-25"
  }, [_c('b-form-input', {
    staticClass: "mr-50",
    staticStyle: {
      "min-width": "150px"
    },
    attrs: {
      "size": "sm",
      "lazy-formatter": "",
      "formatter": _vm.removeAccentsAndTrimAll,
      "trim": "",
      "placeholder": _vm.$t('train.search.result.enterDiscountCode')
    },
    model: {
      value: _vm.promotionInput,
      callback: function callback($$v) {
        _vm.promotionInput = $$v;
      },
      expression: "promotionInput"
    }
  }), _c('b-button', {
    staticClass: "text-nowrap px-75",
    attrs: {
      "variant": "outline-info",
      "size": "sm"
    },
    on: {
      "click": _vm.handleAppliesPromotion
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.apply')) + " ")])], 1), _c('b-col', {
    staticClass: "fw-800 text-success text-right text-nowrap py-25"
  }, [_c('span', {
    staticClass: "font-medium-1 mr-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.totalAmount')) + " ")]), _c('span', {
    staticClass: "font-medium-3 mr-1"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getTotalCartTicketPrice)) + " ")])])], 1), _c('div', [_c('BCardGroup', {
    staticClass: "mb-50 mb-md-1",
    staticStyle: {
      "order": "2"
    },
    attrs: {
      "deck": ""
    }
  }, [_c('BookingAgency', {
    attrs: {
      "agency-data": _vm.agencyData,
      "is-collapsed": _vm.isCollapsedAgencyAndContactDetail
    },
    on: {
      "update:agencyData": function updateAgencyData($event) {
        _vm.agencyData = $event;
      },
      "update:agency-data": function updateAgencyData($event) {
        _vm.agencyData = $event;
      },
      "toggleCollapse": _vm.toggleIsCollapsedAgencyAndContactDetail
    }
  }), _vm.customerData && _vm.defaultCustomerData ? _c('BookingContact', {
    attrs: {
      "customer-data": _vm.customerData,
      "defaultCustomerData": _vm.defaultCustomerData,
      "is-collapsed": _vm.isCollapsedAgencyAndContactDetail
    },
    on: {
      "update:customerData": function updateCustomerData($event) {
        _vm.customerData = $event;
      },
      "update:customer-data": function updateCustomerData($event) {
        _vm.customerData = $event;
      },
      "toggleCollapse": _vm.toggleIsCollapsedAgencyAndContactDetail
    }
  }) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "mt-2"
  }, [_c('BFormCheckbox', {
    staticClass: "fw-700 ml-1",
    model: {
      value: _vm.isConfirmPolicy,
      callback: function callback($$v) {
        _vm.isConfirmPolicy = $$v;
      },
      expression: "isConfirmPolicy"
    }
  }, [_c('div', {
    class: _vm.isMobileView ? 'font-small-2' : 'font-medium-1'
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.termsAndConditions')) + " ")])])], 1), _c('div', {
    staticClass: "d-flex-between mt-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-secondary",
      "pill": ""
    },
    on: {
      "click": _vm.handleBackToSearch
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.goBack')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "success",
      "pill": ""
    },
    on: {
      "click": _vm.handleCreateTicket
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.confirmInfo')) + " ")])], 1)], 1), _c('ModalCheckAdultTicket', {
    on: {
      "confirm": _vm.checkAdultTicketConfirmHandle
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }